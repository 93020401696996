import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";

import Form from "react-bootstrap/Form";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { TagsInput } from "react-tag-input-component";

import FormInput from "../form-input/form-input";
//import SocialMediaEdition from "../social-media-edition/social-media-edition";
import UploadLogo from "../upload-logo/upload-logo";

import InputGroup from "react-bootstrap/InputGroup";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ReactComponent as WhatsApp } from "./../../assests/whatsapp.svg";
import { ReactComponent as Instagram } from "./../../assests/instagram.svg";
import { ReactComponent as Facebook } from "./../../assests/facebook.svg";
import { ReactComponent as Tiktok } from "./../../assests/tiktok.svg";

import { Provinces as provinces } from "../../utils/provinces";
import { Cantons as cantons } from "../../utils/cantons";
import { Districts as districts } from "../../utils/districts";

import {
  createSponsor,
  createSponsorAdmin,
  createSponsorImage,
} from "../../firebase/firebase.utils";
import { fsTimestampCreador } from "../../firebase/firebase-config";
import BranchAddition from "./BranchAddition";
import { ASbutton } from "../buttons/ASbutton";
import { IconButton, useTheme } from "@mui/material";
import { MdAdd, MdEdit, MdPlusOne, MdRemove } from "react-icons/md";
import {
  categories,
  categoriesForSector,
  sectors,
} from "../../utils/sponsorData";
import { refreshToken } from "../../redux/user/actions/auth";

const MySwal = withReactContent(Swal);

const SponsorAddition = ({ onClose, handleRefresh }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [image, setImage] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const typeIdSponsor = [{ name: "Cedula Juridica" }, { name: "RUT" }];
  const [atLeastOneBranchCompleted, setAtLeastOneBranchCompleted] =
    useState(false);

  const countryList = [{ name: "Costa Rica" }];
  const tierList = [
    { key: 1, name: "Basic" },
    { key: 2, name: "Premium" },
    { key: 3, name: "Elite" },
  ];
  const [initialBranchData, setInitialBranchData] = useState([
    {
      city: "",
      location: "",
      province: "San José", // Set the default province to San José
      canton: "",
      district: "",
      countries: "",
      latitude: "",
      longitude: "",
      geopoint: "",
    },
  ]);
  const numberOfBranches = 1; // Define the total number of branches

  const [branches, setBranches] = useState(
    Array.from({ length: numberOfBranches }, () => ({}))
  );

  const { idToken, userGetToken } = useSelector((state) => state.auth);

  const [activeStep, setActiveStep] = useState(0);

  const [availableCategories, setAvailableCategories] = useState(
    categoriesForSector[sectors[0].name]
  );
  const reloadToken = async () => {
    let freshToken = await userGetToken.getIdToken(true);
    return freshToken;
    //setToken(freshToken);
  };

  const validationSchemas = [
    // Validation schema for step 1
    Yup.object({
      // sponsorName: Yup.string().required("Requerido"),
      // sectors: Yup.string(),
      // category: Yup.string(),
      // typeIdSponsor: Yup.string().required("Requerido"),
      // idSponsor: Yup.number()
      //   .typeError("Solo puede ingresar valores numericos")
      //   .required("No es un ID valido"),
    }),
    Yup.object({
      // Add step 3 fields and validations
    }),
    // Validation schema for step 2
    Yup.object({
      facebook: Yup.string(),
      instagram: Yup.string(),
      tiktok: Yup.string(),
      whatsApp: Yup.string(),
      phone: Yup.string(),
      adminEmail: Yup.string()
        .email("Formato no valido")
        .typeError("Formato no valido")
        .required("Requerido"),
    }),

    // Validation schema for step 3 (and so on for more steps)
    Yup.object({
      // Add step 3 fields and validations
    }),
  ];

  const validate = Yup.object({
    sponsorName: Yup.string().required("Requerido"),
    sectors: Yup.string(),
    category: Yup.string(),
    typeIdSponsor: Yup.string(),
    idSponsor: Yup.number()
      .typeError("Solo puede ingresar valores numericos")
      .required("No es un ID valido"),
    countries: Yup.string(),
    province: Yup.mixed().required("Campo requerido"),
    canton: Yup.mixed().required("Campo requerido"),
    district: Yup.mixed().required("Campo requerido"),
    city: Yup.string().required("Requerido"),
    location: Yup.string().required("Requerido"),
    facebook: Yup.string(),
    instagram: Yup.string(),
    tiktok: Yup.string(),
    whatsApp: Yup.string(),
    phone: Yup.string(),
    tier: Yup.string().oneOf(tierList),
    adminEmail: Yup.string()
      .email("Formato no valido")
      .typeError("Formato no valido")
      .required("Requerido"),
    geopoint: "",
  });

  const handleSectorChange = (sector) => {
    setAvailableCategories(categoriesForSector[sector]);
    formik.handleChange({
      target: {
        name: "sectors",
        value: sector,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      sponsorName: "",
      category: categories[0].name,
      typeIdSponsor: typeIdSponsor[0].name,
      idSponsor: "",
      countries: countryList[0].name,
      tier: tierList[0].name,
      // province: "",
      // canton: "",
      // district: "",
      sector: sectors[0].name,
      location: "",
      facebook: "",
      instagram: "",
      tiktok: "",
      whatsApp: "",
      adminEmail: "",
      phone: "",
      latitude: "",
      longitude: "",
    },
    validationSchema: validationSchemas[activeStep],
    onSubmit: async (values, { resetForm }) => {
      const sponsorProducts = selected;
      const creationDate = fsTimestampCreador.fromDate(new Date());

      const sponsorData = {
        typeId: values.typeIdSponsor ?? "",
        businessId: values.idSponsor ?? "",
        name: values.sponsorName ?? "",
        category: [values.category] ?? [],
        country: values.countries ?? "",
        facebook: values.facebook ?? "",
        instagram: values.instagram ?? "",
        tiktok: values.tiktok ?? "",
        whatsApp: values.whatsApp ?? "",
        cdate: creationDate,
        products: sponsorProducts,
        email: values.adminEmail ?? "",
        phone: values.phone ?? "",
        isActive: true,
        tier: values.tier ?? "basic",
      };

      try {
        const confirmResult = await MySwal.fire({
          title: "Crear sponsor",
          text: "¿Estás seguro de que deseas crear este sponsor?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, crear",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        });

        if (!confirmResult.isConfirmed) return;

        const loadingSwal = MySwal.fire({
          title: "Guardando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => MySwal.showLoading(),
        });

        setLoading(true);
        const token = await refreshToken();
        const sponsorId = await createSponsor(sponsorData, branches);
        console.log(sponsorId, sponsorData);
        if (sponsorId) {
          await createSponsorImage(imageUpload, sponsorId);

          const result = await createSponsorAdmin(
            token,
            sponsorId,
            values.adminEmail
          );
          if (result && result.ok) {
            setTimeout(() => {
              setLoading(false);
              handleRefresh();
              resetForm();
              onClose(false);
            }, 2000);

            loadingSwal.close();
            MySwal.fire("Sponsor creado!", "", "success");
          } else {
            loadingSwal.close();
            MySwal.close();
            throw new Error("Failed to create sponsor admin");
          }
        }
      } catch (error) {
        MySwal.close();
        MySwal.fire(
          "Error",
          error.message || "Hubo un error al crear el sponsor",
          "error"
        );
        setLoading(false);
        throw error;
      }
    },
  });

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredCantons(filterItems);

      // Reset the selected canton and district when province changes
      formik.setFieldValue("canton", "");
      formik.setFieldValue("district", "");
    }
  }, [formik.values.province]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton]);

  const addBranch = (branchIndex) => {
    const newBranchData = {
      city: "",
      location: "",
      province: "San José",
      canton: "",
      district: "",
      countries: "",
      latitude: "",
      longitude: "",
      geopoint: "",
      cdate: new Date(),
    };

    setBranches((prevBranches) => {
      if (branchIndex >= 0 && branchIndex < prevBranches.length) {
        // Update the existing branch at the specified index
        const updatedBranches = [...prevBranches];
        updatedBranches.splice(branchIndex + 1, 0, newBranchData); // Insert the new branch
        return updatedBranches;
      } else {
        // Add a new branch at the end
        return [...prevBranches, newBranchData];
      }
    });

    // Update the initialBranchData when a branch is created or modified
    setInitialBranchData((prevData) => {
      const newData = [...prevData];
      newData.splice(branchIndex + 1, 0, newBranchData); // Insert the new branch data
      return newData;
    });
  };

  const saveBranch = (newBranchData, branchIndex) => {
    setBranches((prevBranches) => {
      const updatedBranches = [...prevBranches];
      updatedBranches[branchIndex] = newBranchData;
      return updatedBranches;
    });

    // Update the initialBranchData when a branch is created or modified
    setInitialBranchData((prevData) => {
      const newData = [...prevData];
      newData[branchIndex] = newBranchData;
      return newData;
    });
  };
  useEffect(() => {
    console.log(branches);
  }, [branches]);

  const removeBranch = (index) => {
    const updatedBranches = branches.filter((_, i) => i !== index);
    setBranches(updatedBranches);
  };

  const handleNext = (newData) => {
    // Validate the current step before moving to the next step
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        // No validation errors, can proceed to the next step
        if (activeStep === 1) {
          if (!atLeastOneBranchCompleted) {
            // Display a message or alert to inform the user that they must complete at least one branch.
            return;
          }
        }
        if (activeStep === 2) {
          // Handle submission for the final step
          formik.handleSubmit();
        } else {
          // Move to the next step
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    });
  };

  const handleBack = (newData) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBranchCompleted = () => {
    setAtLeastOneBranchCompleted(true);
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
      noValidate
      className="p-3"
      style={{ minHeight: "50vh" }}
    >
      {/* {Object.keys(errors).map((val) => {
        return <p>{val}</p>;
      })} */}

      {activeStep === 0 && (
        <>
          <Row>
            <Col>
              <Form.Group>
                <FormInput
                  label={"Nombre del comercio"}
                  onChange={formik.handleChange}
                  value={formik.values.sponsorName}
                  type="text"
                  name="sponsorName"
                  placeholder={"Nombre Comercio"}
                  errorList={formik.errors}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.sponsorName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={"6"}>
              <Form.Group>
                <FormInput
                  label={"Teléfono del comercio"}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  type="text"
                  name="phone"
                  placeholder={""}
                  errorList={formik.errors}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={"6"}>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                  Tipo de ID comercial
                </Form.Label>
                <Form.Select
                  size="sm"
                  onChange={formik.handleChange}
                  name="typeIdSponsor"
                  value={formik.values.typeIdSponsor}
                  className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                >
                  {typeIdSponsor.map((idType, index) => {
                    return (
                      <option
                        value={idType.name}
                        key={index}
                        name="typeIdSponsor"
                      >
                        {idType.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={"6"}>
              <Form.Group>
                <FormInput
                  label={"ID comercial"}
                  onChange={formik.handleChange}
                  value={formik.values.idSponsor}
                  type="text"
                  name="idSponsor"
                  placeholder={""}
                  errorList={formik.errors}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.idSponsor}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={"6"} className="mt-2">
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>Sector</Form.Label>
                <Form.Select
                  size="sm"
                  onChange={(e) => handleSectorChange(e.target.value)}
                  name="sector"
                  value={formik.values.sector}
                  className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                >
                  {sectors.map((sector) => (
                    <option key={sector.name} value={sector.name}>
                      {sector.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={"6"} className="mt-2">
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>Categoria</Form.Label>
                <Form.Select
                  size="sm"
                  onChange={formik.handleChange}
                  name="category"
                  value={formik.values.category}
                  className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                >
                  {availableCategories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>Tier</Form.Label>
                <Form.Select
                  size="sm"
                  onChange={formik.handleChange}
                  name="tier"
                  value={formik.values.tier}
                  className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                >
                  {tierList.map((tierItem) => (
                    <option key={tierItem.key} value={tierItem.name}>
                      {tierItem.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  Productos que ofrece
                </Form.Label>
                <TagsInput
                  value={selected}
                  style={{ background: "red" }}
                  onChange={setSelected}
                  name="tags"
                  placeHolder="tag ↵ Enter"
                  classNames={{
                    input:
                      "border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none",
                    tag: "bg-tags",
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      {activeStep === 1 && (
        <>
          {branches.map((branch, index) => (
            <div key={index}>
              <Row className="mb-4 mt-2">
                <Col md={"1"} className="ms-auto ">
                  <IconButton
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                    }}
                    onClick={() => addBranch(index)}
                  >
                    <MdAdd size={14} />
                  </IconButton>
                </Col>
                <Col md={"1"} className="me-2">
                  <IconButton
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                    }}
                    onClick={() => removeBranch(index)}
                  >
                    <MdRemove size={14} />
                  </IconButton>
                </Col>
              </Row>
              <BranchAddition
                onBranchCompleted={handleBranchCompleted}
                countryList={countryList}
                provinces={provinces}
                cantons={cantons}
                districts={districts}
                branchIndex={index}
                onBranchSave={saveBranch}
                initialValues={initialBranchData[index]}
              />
            </div>
          ))}
        </>
      )}
      {activeStep === 2 && (
        <>
          <Row>
            <Col>
              {/* <SocialMediaEdition
            handleInput={handleChange}
            formikValues={values}
          /> */}
              <div className="mt-3">
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }}>
                    Redes sociales
                  </Form.Label>
                  <InputGroup className="position-relative">
                    <InputGroup.Text
                      className="p-0 bg-transparent border-0 position-absolute"
                      style={{ zIndex: 10, bottom: "15px" }}
                    >
                      <WhatsApp style={{ width: "20px", height: "20px" }} />
                    </InputGroup.Text>
                    <FormInput
                      type="text"
                      name="whatsApp"
                      errorList={{ whatsApp: false }}
                      style={{ paddingLeft: "25px" }}
                      value={formik.values.whatsApp}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>

                  <InputGroup className="position-relative">
                    <InputGroup.Text
                      className="p-0 bg-transparent border-0 position-absolute"
                      style={{ zIndex: 10, bottom: "15px" }}
                    >
                      <Instagram style={{ width: "20px", height: "20px" }} />
                    </InputGroup.Text>
                    <FormInput
                      type="text"
                      name="instagram"
                      errorList={{ instagram: false }}
                      style={{ paddingLeft: "25px" }}
                      value={formik.values.instagram}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>

                  <InputGroup className="position-relative">
                    <InputGroup.Text
                      className="p-0 bg-transparent border-0 position-absolute"
                      style={{ zIndex: 10, bottom: "15px" }}
                    >
                      <Facebook style={{ width: "20px", height: "20px" }} />
                    </InputGroup.Text>
                    <FormInput
                      type="text"
                      name="facebook"
                      errorList={{ facebook: false }}
                      style={{ paddingLeft: "25px" }}
                      value={formik.values.facebook}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>

                  <InputGroup className="position-relative">
                    <InputGroup.Text
                      className="p-0 bg-transparent border-0 position-absolute"
                      style={{ zIndex: 10, bottom: "15px" }}
                    >
                      <Tiktok style={{ width: "20px", height: "20px" }} />
                    </InputGroup.Text>
                    <FormInput
                      type="text"
                      name="tiktok"
                      errorList={{ tiktok: false }}
                      style={{ paddingLeft: "25px" }}
                      value={formik.values.tiktok}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <UploadLogo
                title={"Logotipo"}
                loadUrl={setFileUrl}
                imageUpload={imageUpload}
                setImageUpload={setImageUpload}
                setImage={setImage}
                image={image}
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Form.Group>
                <FormInput
                  label={"Admin email"}
                  onChange={formik.handleChange}
                  value={formik.values.adminEmail}
                  type="text"
                  name="adminEmail"
                  placeholder={""}
                  errorList={formik.errors}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.adminEmail}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}

      <Row className="mt-4 align-items-center">
        {activeStep === 2 && (
          <Col>
            <Form.Check
              type={"checkbox"}
              id={"sponsorNotification"}
              label={"Notificar al sponsor"}
              style={{ fontSize: "14px" }}
              className="mb-0"
            />
          </Col>
        )}

        <Col className="d-flex ">
          {activeStep !== 0 && (
            <Button
              variant="primary"
              size="md"
              className="rounded-pill text-light px-3 ms-auto me-4"
              onClick={handleBack}
              disabled={loading}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {!loading && (
                <span style={{ color: "white !important" }}>Atrás</span>
              )}
            </Button>
          )}
          <Button
            variant="primary"
            size="md"
            className={
              activeStep === 0
                ? "rounded-pill text-light px-3 ms-auto"
                : "rounded-pill text-light px-3 "
            }
            disabled={loading || (activeStep === 2 && !formik.isValid)}
            onClick={activeStep === 2 ? formik.handleSubmit : handleNext}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!loading && (
              <span style={{ color: "white !important" }}>
                {activeStep === 2 ? "Crear" : "Siguiente"}
              </span>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SponsorAddition;
