import React, { useEffect, useState } from "react";
import { Row, Col, Form, Accordion } from "react-bootstrap";
import FormInput from "../form-input/form-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import { MdCheck } from "react-icons/md";

function BranchAddition({
  countryList,
  provinces,
  cantons,
  initialValues,
  districts,
  branchIndex,
  onBranchSave,
  onBranchCompleted, // Define the onBranchCompleted callback
}) {
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Add state for tracking unsaved changes
  const [submittedBranches, setSubmittedBranches] = useState({}); // Track submissions per branch

  const initialFormValues = initialValues || {
    city: "",
    location: "",
    province: "San José",
    district: "",
    countries: "CR",
    latitude: "",
    longitude: "",
    geopoint: "",
  };

  const validationSchema = Yup.object().shape({
    city: Yup.string().required("Campo Requerido"),
    // location: Yup.string().required("Campo Requerido"),
    latitude: Yup.number()
      .required("Campo Requerido")
      .typeError("Debe ser un numero"),
    longitude: Yup.number()
      .required("Campo Requerido")
      .typeError("Debe ser un numero"),
  });
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        city: values.city,
        location: values.location,
        province: values.province,
        canton: values.canton,
        district: values.district,
        geopoint: new firebase.firestore.GeoPoint(
          parseFloat(values.latitude),
          parseFloat(values.longitude)
        ),
        cdate: new Date(),
      };

      console.log(data, branchIndex);
      onBranchSave(data, branchIndex);
      setHasUnsavedChanges(false);
      setSubmittedBranches((prev) => ({ ...prev, [branchIndex]: true })); // Set only for this branch

      onBranchCompleted();
    },
  });

  useEffect(() => {
    const selectedProvince = formik.values.province;

    if (selectedProvince) {
      const filterItems = cantons.filter(
        (canton) => canton.provinceName === selectedProvince
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredCantons(filterItems);

      formik.setFieldValue("canton", "");
      formik.setFieldValue("district", "");
    }
  }, [formik.values.province]);

  useEffect(() => {
    const selectedCanton = formik.values.canton;

    if (selectedCanton) {
      const filterItems = districts.filter(
        (district) => district.cantonName === selectedCanton
      );

      filterItems.sort(function (firstValue, secondValue) {
        if (firstValue.label < secondValue.label) {
          return -1;
        }
        if (firstValue.label > secondValue.label) {
          return 1;
        }
        return 0;
      });

      setFilteredDistricts(filterItems);
    }
  }, [formik.values.canton]);

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{`Sucursal ${branchIndex + 1}`}</Accordion.Header>
          <Accordion.Body>
            <Row className="mt-2">
              <Col>
                <Form.Group>
                  <Form.Label style={{ fontSize: "14px" }} className="mb-3">
                    Pais
                  </Form.Label>
                  <Form.Select
                    size="sm"
                    onChange={formik.handleChange}
                    name={`countries`}
                    value={formik.values.countries}
                    className="border-top-0 border-start-0 border-end-0 bg-light rounded-0 shadow-none mb-2"
                  >
                    {countryList.map((country) => (
                      <option value={country.name} key={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Provincia</Form.Label>
                  <Form.Control
                    as="select"
                    name={`province`}
                    value={formik.values.province}
                    onChange={(e) =>
                      formik.setFieldValue("province", e.target.value)
                    }
                  >
                    {provinces.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Cantón</Form.Label>
                  <Form.Control
                    as="select"
                    name={`canton`}
                    value={formik.values.canton}
                    onChange={formik.handleChange}
                  >
                    {filteredCantons.map((option) => (
                      <option key={option.label} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Distrito</Form.Label>
                  <Form.Control
                    as="select"
                    name={`district`}
                    value={formik.values.district}
                    onChange={formik.handleChange}
                  >
                    {filteredDistricts.map((option) => (
                      <option key={option.label} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <FormInput
                    label={"Ciudad"}
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    type="text"
                    name={`city`}
                    placeholder={"San Jose"}
                    errorList={formik.errors}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <FormInput
                    label={"Ubicación"}
                    onChange={formik.handleChange}
                    value={formik.values.location}
                    type="text"
                    name={`location`}
                    placeholder={"https://googlemaps"}
                    errorList={formik.errors}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.location}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <FormInput
                    label={"Latitud"}
                    onChange={formik.handleChange}
                    value={formik.values.latitude}
                    type="text"
                    name={`latitude`}
                    placeholder={"9.00"}
                    errorList={formik.errors}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.latitude}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <FormInput
                    label={"Longitud"}
                    onChange={formik.handleChange}
                    value={formik.values.longitude}
                    type="text"
                    name={`longitude`}
                    placeholder={"-84.00"}
                    errorList={formik.errors}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.longitude}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/* Submit Button with Success Checkmark */}
            <div className="text-center mt-3">
              <button
                type="submit"
                className="btn btn-primary rounded-pill"
                onClick={formik.handleSubmit}
              >
                {submittedBranches[branchIndex] ? "Guardado" : "Guardar"}
              </button>
              {submittedBranches[branchIndex] && (
                <MdCheck color="green" size={24} style={{ marginLeft: 2 }} />
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {hasUnsavedChanges && ( // Display unsaved changes indicator if changes are detected
        <div variant="warning">You have unsaved changes.</div>
      )}
    </>
  );
}

export default BranchAddition;
